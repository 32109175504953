import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, } from '@angular/forms';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { ModalService } from '../../services/modal.service';
import { AsyncResult } from '../../models/helper-models';
import { QuestionAnswerInputModel } from '../../models/question-answer-inputmodel';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from "../../models/grid/grid.config";
import { AttendanceDocumentReportViewModel } from "../../models/attendance-document-report-viewmodel";
import { Table } from "../../enums/table.enum";

@Component({
  selector: 'obc-site-attendance',
  templateUrl: './site-attendance.component.html',
  styleUrls: ['./site-attendance.component.css'],
})
export class SiteAttendanceComponent implements OnInit {
  @ViewChild('grid') grid: any;

  inProgress: boolean = false;
  searchQueryForm: FormGroup = null;
  questionAnswerInputModel: QuestionAnswerInputModel = new QuestionAnswerInputModel();
  initialFilters: InitialFilter[] = [];
  UserPermission = UserPermission;

  constructor(
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private siteAttendanceService: SiteAttendanceService,
    private modalService: ModalService,
    public userService: UserService,
  ) {
  }

  ngOnInit() {
    this.layoutService.header = 'Live Attendance Report';
    this.searchQueryForm = this.formBuilder.group({
      date: new FormControl(null),
    });

    this.searchQueryForm?.controls.date?.valueChanges?.subscribe((res) => {
      let filter = this.initialFilters?.find((item) => item.key == 'liveDate');
      if (res) {
        filter.value = res;
      } else {
        filter.value = null;
      }
      this.initialFilters = [
        ...this.initialFilters?.filter((item) => item.key != 'liveDate'),
        filter,
      ];
    })

    this.prepareGridConfig();
  }

  setNow() {
    this.searchQueryForm.get('date').setValue(new Date());
  }

  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);

    this.initialFilters.push({
      key: 'liveDate',
      value: null,
      displayInFilterRenderer: false,
    });

    this.gridConfig = new GridConfig({
      apiUrl: '/api/SiteAttendance/site-attendance-web-report',
      tableType: Table.LiveAttendance,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter Result by Mobile, First Name, Last Name, Site",
      initialFilters: this.initialFilters,
      csvReportUrl: '/api/SiteAttendance/export-live-attendance-report-csv-as-job',
      generalSearchApiKeyName: 'searchTermToFilterUser',
    });
    this.gridColumns = [
      {
        name: "Full Name",
        key: "firstName",
        type: FilterType.Text,
        propertyNameInQuery: 'names',
        gridTemplate: new GridTemplate().FullNameAccompanierCount({
          firstName: 'firstName',
          lastName: 'lastName',
          accompaniersText: 'accompaniers',
          accompanierCount: 'accompanierCount'
        }).CsvFields([
          'firstName',
          'lastName',
          'accompaniers',
          'accompanierCount',
        ]),
      },
      {
        name: "Supplier",
        key: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'supplierIds',
      },
      {
        name: "Mobile",
        key: "mobile",
        type: FilterType.Mobile,
        propertyNameInQuery: 'userIds',
      },
      {
        name: "Site",
        key: "siteName",
        sortBy: "site",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIds',
        gridTemplate: new GridTemplate().SiteNameReference({
          siteName: 'site',
          siteReference: 'siteReference',
        }).CsvFields([
          'site',
          'siteReference',
        ]),
      },
      {
        name: "Check In Time",
        key: "checkInDate",
        gridTemplate: new GridTemplate().ComponentTemplateName('checkInDateTime')
          .CsvFields([
            'checkInDate',
            'checkInForm'
          ]),
        enableFilter: false,
      },
      {
        name: "Check Out Time",
        key: "checkOutDate",
        gridTemplate: new GridTemplate().ComponentTemplateName('checkOutDateTime')
          .CsvFields([
            'checkOutDate',
            'checkOutForm'
          ]),
        enableFilter: false,
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.checkOutDate == undefined;
          });
        }
      },
      {
        name: "Email",
        key: "email",
        type: FilterType.Text,
        propertyNameInQuery: 'emails',
      },
      {
        name: "Gender",
        key: "gender",
        type: FilterType.Gender,
        propertyNameInQuery: 'genders',
      },
      {
        name: "Date of Birth",
        key: "birthDay",
        type: FilterType.DateRange,
        propertyNameInQuery: 'birthdayRanges',
      },
      {
        name: "Industry",
        key: "industryTypeName",
        type: FilterType.IndustryType,
        propertyNameInQuery: 'industryTypes',
      },
      {
        name: "Business",
        key: "businessName",
        type: FilterType.Text,
        propertyNameInQuery: 'businusses',
      },
      {
        name: "Induction",
        key: "siteInductions",
        type: FilterType.InductionId,
        propertyNameInQuery: 'InductionIds',
        enableSort: false,
        gridTemplate: new GridTemplate().Inductions({
          inductions: 'siteInductions',
        }),
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.siteInductions == undefined || item.siteInductions?.length == 0;
          });
        }
      },
      {
        name: "Duration Onsite",
        key: 'workingDuration',
        type: FilterType.Duration,
        enableSort: false,
        propertyNameInQuery: 'durationRange',
        isArray: false,
      },
      {
        name: "Supplier Document Types",
        key: 'supplierDocuments',
        enableSort: false,
        enableFilter: false,
        propertyNameInQuery: "includeSupplierDocuments",
        isArray: false,
        hasIncludeFilter: true,
        visible: false,
        gridTemplate: new GridTemplate().SupplierDocuments({
          supplierDocuments: 'supplierDocuments',
        }),
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.supplierDocuments == undefined || item.supplierDocuments?.length == 0;
          });
        }
      },
      {
        name: "Details",
        key: 'viewDetails',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      },
    ];
  }


  openModal(
    template,
    siteAttendanceId: number,
    isCheckInAnswer: boolean,
    hasCheckOutDate: boolean
  ) {
    this.questionAnswerInputModel = {
      isCheckInAnswer: isCheckInAnswer,
      siteAttendanceId: siteAttendanceId,
      hasCheckOutDate: hasCheckOutDate,
    };
    this.modalService.show(template, 'modal-lg');
  }

  closeModal(_: AsyncResult) {
    this.modalService.hide();
  }

  isShowTemplateColumns(mainColumn, detailColumn) {
    return this.grid.isShowTemplateColumns(mainColumn, detailColumn);
  }

}

