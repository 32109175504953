import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteAttendanceService } from '../../services/site-attendance.service';
import { QuestionAnswerInputModel } from '../../models/question-answer-inputmodel';
import { AsyncResult } from '../../models/helper-models';
import { SiteQuestionAnswerViewModel } from 'src/app/models/site_question_answer_view_model';
import { QuestionType } from 'src/app/enums/question-type';
import { SiteAttendanceDocumentViewModel } from 'src/app/models/site-attendance-document-viewmodel';
import { finalize } from 'rxjs/operators';
import { SiteAttendanceAccompanierModel } from 'src/app/models/site-attendance-accompanier-model';
import { UserContactInformation } from 'src/app/models/user-profile-viewmodel';
import { AnnouncementVisitViewModel } from '../../models/announcement-visit-view-model';
import { FileType } from '../../enums/file-type.enum';
import { AttendanceScannedItemViewModel } from 'src/app/models/attendance-scanned-item-viewmodel';
import { AnswerMode } from 'src/app/enums/answer-mode.enum';
import { AttendanceFieldViewModel, AttendanceFieldWithValue } from 'src/app/models/attendance-field-model';
import { ModalService } from 'src/app/services/modal.service';
import { SiteInductionViewModel } from 'src/app/models/site-induction-view-model';
import { DocumentProvider } from 'src/app/enums/document-provider.enum';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SiteAttendanceDisputeType } from 'src/app/enums/site-attendance-dispute-type.enum';
import { FilledFormInfoViewModel } from 'src/app/models/filled-form-info-view-model';
import { clone } from 'src/app/helpers/general-functions';
import { SiteInductionFilledInfoViewModel } from 'src/app/models/induction/site-induction-filled-info-view-model';
import { SupplierDocumentViewModel } from 'src/app/models/supplier-document/site-supplier-document-version-signoff-viewModel';
import { SiteAttendanceApprovalStatusViewModel } from 'src/app/models/site-attendance-approval-status';
import { SiteAttendanceApprovalStatus } from 'src/app/enums/site-attendance-approval-status.enum';
import { FormType } from "../../enums/form-type";
import { SiteAttendanceAttachmentsViewModel } from "../../models/site-attendance-attachments-viewmodel";
import { EmergencyContactViewModel } from 'src/app/models/emergency-contact-view-model';

@Component({
  selector: 'obc-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss'],
})
export class QuestionAnswerComponent implements OnInit {
  SiteAttendanceApprovalStatus = SiteAttendanceApprovalStatus;
  @Output() public result = new EventEmitter<AsyncResult>();
  @Output() public resultOfApproveReject = new EventEmitter();
  needParentRefresh: boolean = false;
  questionAnswer: QuestionAnswerInputModel;
  DocumentProvider = DocumentProvider;
  siteInductionFilledInfo: SiteInductionFilledInfoViewModel[];
  siteAttendanceApprovalStatus: SiteAttendanceApprovalStatusViewModel;
  @Input() set questionAnswerInput(
    _questionAnswerInput: QuestionAnswerInputModel
  ) {
    if (!_questionAnswerInput) return;
    this.questionAnswer = _questionAnswerInput;
  }

  checkInAnswerResult: SiteQuestionAnswerViewModel[];
  checkOutAnswerResult: SiteQuestionAnswerViewModel[];
  siteAttendanceDocuments: SiteAttendanceDocumentViewModel[];
  accompanyingPersons: SiteAttendanceAccompanierModel[];
  visitedAnnouncements: AnnouncementVisitViewModel[];
  scannedItems: AttendanceScannedItemViewModel[];
  userInformation: UserContactInformation;
  announcementType = FileType;
  siteInductions: SiteInductionViewModel[];
  forms: FilledFormInfoViewModel[];
  permitForms: FilledFormInfoViewModel[];
  supplierDocuments: SupplierDocumentViewModel[];

  isCheckInSelected: boolean = true;
  errorMessage = '';
  inProgress: boolean = false;
  questionType = QuestionType;
  userInformationFields: any[];
  showDisputeHistoryType: SiteAttendanceDisputeType;

  @Input() largeButton = true;
  anonymousAttendanceKey = null;
  @Output() onAnnouncementVisit = new EventEmitter<number>();
  callOnAnnouncementVisitEvent(announcementId: number) {
    this.onAnnouncementVisit.emit(announcementId);
  }
  @Output() onCheckedMandatorySignOff = new EventEmitter<number>();
  callOnAnnouncementCheckedMandatorySignOffEvent(announcementId: number) {
    this.onCheckedMandatorySignOff.emit(announcementId);
  }

  get answerResult() {
    return this.isCheckInSelected
      ? this.checkInAnswerResult?.filter(a => a.siteInductionId == null)
      : this.checkOutAnswerResult;
  }
  get completeAnswerDate() {
    return this.userInformation == null ? null : this.isCheckInSelected
      ? (this.userInformation.checkInAnswerMode == AnswerMode.DelayedAnswer
        ? this.userInformation.completeCheckInAnswerDate
        : null)
      : (this.userInformation.checkOutAnswerMode == AnswerMode.DelayedAnswer
        ? this.userInformation.completeCheckOutAnswerDate
        : null);
  }

  constructor(private siteAttendanceService: SiteAttendanceService,
    private bsModalService: BsModalService,
    public modalService: ModalService) { }

  ngOnInit(): void {
    this.getData();
  }

  get showUserInformationPanel(): boolean {
    return (
      this.userInformation != null &&
      (this.userInformation.mobile != null ||
        this.userInformation.firstName != null ||
        this.userInformation.lastName != null)
    );
  }

  getData() {
    this.inProgress = true;
    this.siteAttendanceService
      .getSiteAttendanceAttachments(this.questionAnswer.siteAttendanceId)
      .subscribe(
        (res) => {
          this.setData(res);
        },
        (error) => {
          this.errorMessage = 'We were not able to retrieve the result';
        },
        () => {
          this.inProgress = false;
        }
      );
  }

  setData(res: SiteAttendanceAttachmentsViewModel) {
    this.checkInAnswerResult = res.checkInQuestionAnswers;
    this.checkOutAnswerResult = res.checkOutQuestionAnswers;
    this.siteAttendanceDocuments = res.documents;
    this.accompanyingPersons = res.accompaniers;
    this.userInformation = res.userInformation;
    this.visitedAnnouncements = res.visitedAnnouncements;
    this.scannedItems = res.scannedItems;
    this.siteInductions = res.siteInductions;
    this.forms = res.forms?.filter(item => item.type != FormType.Permit);
    this.permitForms = res.forms?.filter(item => item.type == FormType.Permit);
    this.supplierDocuments = res.supplierDocuments;
    this.siteAttendanceApprovalStatus = res.siteAttendanceApprovalStatus

    if (this.questionAnswer)
      this.questionAnswer.hasCheckOutDate = this.userInformation.checkOutDate != null;

    //Must be last expression
    this.setUserInformationFields();
    this.getSiteInductionsFilledInfo();
  }

  setUserInformationFields() {
    this.userInformationFields = [
      { title: 'Site', key: 'siteName', icon: 'map-marker-alt' },
      { title: 'Supplier', key: 'companySupplier', icon: 'industry' },
      { title: 'CheckIn', key: 'checkInDate', icon: 'calendar', type: 'datetime' },
      { title: 'CheckOut', key: 'checkOutDate', icon: 'calendar', type: 'datetime' },
      { title: 'First Name', key: 'firstName', icon: 'user' },
      { title: 'Last Name', key: 'lastName', icon: 'user' },
      { title: 'Mobile', key: 'mobile', icon: 'phone', type: 'mobile' },
      {
        title: 'Date of Birth',
        key: 'birthDay',
        icon: 'calendar',
        type: 'date',
      },
      { title: 'Gender', key: 'gender', icon: 'male', type: 'gender' },
      { title: 'Industry Type', key: 'industryTypeText', icon: 'industry' },
      { title: 'Emergency Contact', key: 'emergencyContactAsJson', icon: 'phone-square', type: 'emergencyContact' },
      { title: 'Business Name', key: 'businessName', icon: 'id-card' },
      { title: 'Email', key: 'email', icon: 'envelope', type: 'email' },
      { title: 'Visitor Type', key: 'visitorTypeTitle', icon: 'user-circle' },
      // { title: 'Induction', key: 'induction', type: 'induction', icon: 'id-card' },
    ].filter((f) =>
      (this.userInformation[f.key] != null &&
        (f.key != 'emergencyContactAsJson' || !EmergencyContactViewModel.isEmpty(JSON.parse(this.userInformation[f.key])))) ||
      (f.key == 'induction' && this.siteInductions != null));//allowComminiucateWithUser's value can be null
  }
  getAnnouncementTypeLabel(type: FileType) {
    if (type == this.announcementType.ExternalLink)
      return "External Link";
    else
      return this.announcementType[type];
  }

  onApproveOrReject($event: SiteInductionViewModel, induction: SiteInductionFilledInfoViewModel) {
    this.getData();
    this.needParentRefresh = true;
    this.resultOfApproveReject.emit()
  }

  answerResultOfInduction(induction: SiteInductionViewModel) {
    return this.checkInAnswerResult?.filter(a => a.siteInductionId == induction.siteInductionId);
  }
  documentResultOfInduction(induction: SiteInductionViewModel) {
    return this.siteAttendanceDocuments?.filter(a => a.siteInductionId == induction.siteInductionId);
  }

  get onlySiteAttendanceDocuments() {
    return this.siteAttendanceDocuments?.filter(doc => doc.siteInductionId == null);
  }

  disputeModalRef: any = null;
  onShowDisputeModal(template) {
    this.disputeModalRef = this.bsModalService.show(template);
  }

  onUpdateAttachment($event) {
    this.setData($event);
    this.disputeModalRef.hide();
  }

  showDisputHistory(showCheckInHistory: boolean, template) {
    this.showDisputeHistoryType = showCheckInHistory ? SiteAttendanceDisputeType.CheckInDate : SiteAttendanceDisputeType.CheckOutDate;
    this.disputeModalRef = this.bsModalService.show(template);
  }
  getSiteInductionsFilledInfo() {
    this.siteInductionFilledInfo = this.siteInductions.map(siteInduction => {
      var fullInduction = clone(siteInduction) as SiteInductionFilledInfoViewModel;
      fullInduction.siteInductionQuestionAnswers = this.answerResultOfInduction(siteInduction);
      fullInduction.siteInductionDocuments = this.documentResultOfInduction(siteInduction);
      return fullInduction;
    });
  }
}
